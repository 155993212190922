import * as React from "react";

import Breadcrumps from "@components/Breadcrumbs";
import BigCommunique from "@components/BigCommunique";

function MessageSentPageEN() {
  const breadcrumbs_data = [{ name: "Message sent" }];
  return (
    <div className="message-sent-page">
      <div className="container">
        <Breadcrumps data={breadcrumbs_data} />
        <main>
          <BigCommunique description="Your message has been sent successfully. We will contact you as soon as possible.">
            <h1>Your message has been sent.</h1>
          </BigCommunique>
        </main>
      </div>
    </div>
  );
}

export default MessageSentPageEN;
