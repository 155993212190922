import "./styles.scss";

import React from "react";
import { useLocale } from "@context/locale";

import Button from "@components/Button";

function BigCommunique({ description, children }) {
  const { t } = useLocale();
  return (
    <div className="big-communique">
      <div className="big-communique__main">{children}</div>
      <p className="big-communique__description">{description}</p>
      <Button href={t("index_link")}>{t("Strona główna")}</Button>
    </div>
  );
}

export default BigCommunique;
